this["LE"] = this["LE"] || {};
this["LE"]["templates"] = this["LE"]["templates"] || {};
this["LE"]["templates"]["audio_player"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"cue-track\" data-track=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"item_id") || (depth0 != null ? lookupProperty(depth0,"item_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"item_id","hash":{},"data":data,"loc":{"start":{"line":2,"column":38},"end":{"line":2,"column":51}}}) : helper)))
    + "\">\n        <span class=\"cue-track-details cue-track-cell\">\n            <span class=\"cue-track-title\" itemprop=\"name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":58},"end":{"line":4,"column":69}}}) : helper)))
    + "</span>\n        </span>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"tracks") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":7,"column":9}}})) != null ? stack1 : "");
},"useData":true});
this["LE"]["templates"]["row_collapse"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"item\">\n                            <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"filepath") || (depth0 != null ? lookupProperty(depth0,"filepath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filepath","hash":{},"data":data,"loc":{"start":{"line":46,"column":37},"end":{"line":46,"column":51}}}) : helper)))
    + "\" target=\"_blank\" class=\"text-uppercase\">\n                                "
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":47,"column":32},"end":{"line":47,"column":41}}}) : helper)))
    + "\n                            </a>\n                        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"col-sm-12 col-notes\">\n        <h3 class=\"font-francois-one\">Additional Notes</h3>\n        <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"notes") || (depth0 != null ? lookupProperty(depth0,"notes") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"notes","hash":{},"data":data,"loc":{"start":{"line":59,"column":11},"end":{"line":59,"column":24}}}) : helper))) != null ? stack1 : "")
    + "</p>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row-collapse row no-gutters\" data-lesson-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"lesson_id") || (depth0 != null ? lookupProperty(depth0,"lesson_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lesson_id","hash":{},"data":data,"loc":{"start":{"line":1,"column":57},"end":{"line":1,"column":72}}}) : helper)))
    + "\" style=\"display: none\">\n    <div class=\"col-sm-4\">\n        <div class=\"blok blok-c blok-video\" data-video=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"video_filepath") || (depth0 != null ? lookupProperty(depth0,"video_filepath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"video_filepath","hash":{},"data":data,"loc":{"start":{"line":3,"column":56},"end":{"line":3,"column":76}}}) : helper)))
    + "\">\n            <div class=\"inner\">\n                <div class=\"top\">\n                    <img alt=\"video\" src=\"img/video.svg\">\n                </div>\n                <div class=\"bottom\">\n                    <span class=\"text-uppercase font-francois-one\">Video</span>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class=\"col-sm-4\">\n        <div class=\"blok blok-g blok-audio\">\n            <div class=\"inner\">\n                <div class=\"top\">\n                    <img alt=\"audio\" src=\"img/audio.svg\">\n                </div>\n                <div class=\"bottom\">\n                    <span class=\"text-uppercase font-francois-one\">Audio</span>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class=\"col-sm-4\">\n        <div class=\"blok-rotate-container manual-flip\">\n            <div class=\"blok blok-y blok-resources blok-rotate\">\n                <div class=\"front\">\n                    <div class=\"inner\">\n                        <div class=\"top\">\n                            <img alt=\"resources\" src=\"img/resources.svg\">\n                        </div>\n                        <div class=\"bottom\">\n                            <span class=\"text-uppercase font-francois-one\">Resources</span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"back\">\n                    <div class=\"rotate-back\">\n                        <i class=\"glyphicon glyphicon-retweet\"></i>\n                    </div>\n                    <ul class=\"resource-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"resources") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":24},"end":{"line":50,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\n                </div>\n            </div>\n        </div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notes") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":4},"end":{"line":61,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});
this["LE"]["templates"]["timetable_day"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"text-left text-upper\">\n            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":20}}}) : helper)))
    + "\n        </p>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"timeblock") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":49,"column":17}}})) != null ? stack1 : "")
    + "\n        <table class=\"table-timetable-desktop table table-striped table-bordered hidden-xs\">\n            <thead>\n            <tr>\n                <th>Time</th>\n                <th>Students</th>\n                <th>Room</th>\n                <th>Instrument</th>\n            </tr>\n            </thead>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"timeblock") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":12},"end":{"line":85,"column":21}}})) != null ? stack1 : "")
    + "        </table>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <table class=\"table-timetable-mobile table table-bordered visible-xs\">\n                <tr>\n                    <th class='text-right'>\n                        <strong>Time</strong>\n                    </th>\n                    <td>\n                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"start") || (depth0 != null ? lookupProperty(depth0,"start") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start","hash":{},"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":14,"column":33}}}) : helper)))
    + "\n                        <br>\n                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"end") || (depth0 != null ? lookupProperty(depth0,"end") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"end","hash":{},"data":data,"loc":{"start":{"line":16,"column":24},"end":{"line":16,"column":31}}}) : helper)))
    + "\n                    </td>\n                </tr>\n                <tr>\n                    <th class='text-right'>\n                        <strong>Room</strong>\n                    </th>\n                    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"room") || (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"room","hash":{},"data":data,"loc":{"start":{"line":23,"column":24},"end":{"line":23,"column":32}}}) : helper)))
    + "</td>\n                </tr>\n                <tr>\n                    <th class='text-right'>\n                        <strong>Instrument</strong>\n                    </th>\n                    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"instrument") || (depth0 != null ? lookupProperty(depth0,"instrument") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"instrument","hash":{},"data":data,"loc":{"start":{"line":29,"column":24},"end":{"line":29,"column":38}}}) : helper)))
    + "</td>\n                </tr>\n                <tr>\n                    <th class='text-right'>\n                        <strong>Students</strong>\n                    </th>\n                    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":24},"end":{"line":38,"column":31}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"students") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":24},"end":{"line":45,"column":31}}})) != null ? stack1 : "")
    + "                    </td>\n                </tr>\n            </table>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"label","hash":{},"data":data,"loc":{"start":{"line":37,"column":28},"end":{"line":37,"column":37}}}) : helper)))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"students") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":28},"end":{"line":44,"column":37}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":42,"column":32},"end":{"line":42,"column":40}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"year") || (depth0 != null ? lookupProperty(depth0,"year") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"year","hash":{},"data":data,"loc":{"start":{"line":42,"column":42},"end":{"line":42,"column":50}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":42,"column":51},"end":{"line":42,"column":60}}}) : helper)))
    + ")\n                                <br>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n                    <td class=\"\" valign=\"top\">\n                        <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"start") || (depth0 != null ? lookupProperty(depth0,"start") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start","hash":{},"data":data,"loc":{"start":{"line":63,"column":32},"end":{"line":63,"column":41}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"end") || (depth0 != null ? lookupProperty(depth0,"end") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"end","hash":{},"data":data,"loc":{"start":{"line":63,"column":44},"end":{"line":63,"column":51}}}) : helper)))
    + "</strong>\n                    </td>\n                    <td class=\"\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":24},"end":{"line":69,"column":31}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"students") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":24},"end":{"line":76,"column":31}}})) != null ? stack1 : "")
    + "                    </td>\n                    <td class=\"\" valign=\"top\">\n                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"room") || (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"room","hash":{},"data":data,"loc":{"start":{"line":79,"column":24},"end":{"line":79,"column":32}}}) : helper)))
    + "\n                    </td>\n                    <td>\n                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"instrument") || (depth0 != null ? lookupProperty(depth0,"instrument") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"instrument","hash":{},"data":data,"loc":{"start":{"line":82,"column":24},"end":{"line":82,"column":38}}}) : helper)))
    + "\n                    </td>\n                </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"timetable__day\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":88,"column":13}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});
this["LE"]["templates"]["timetable_tutor_day"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":7,"column":31},"end":{"line":7,"column":37}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":7,"column":39},"end":{"line":7,"column":47}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<hr class=\"featurette-divider\">\n<div data-tutor-block data-tutor=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"tutorId") || (depth0 != null ? lookupProperty(depth0,"tutorId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tutorId","hash":{},"data":data,"loc":{"start":{"line":2,"column":34},"end":{"line":2,"column":47}}}) : helper)))
    + "\" data-day=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"day") || (depth0 != null ? lookupProperty(depth0,"day") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"day","hash":{},"data":data,"loc":{"start":{"line":2,"column":59},"end":{"line":2,"column":68}}}) : helper)))
    + "\">\n    <div class=\"row form-inline\">\n        <div class=\"col-sm-9\">\n            <select class=\"pull-left\" name=\"tutor\" placeholder=\"Select tutor\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"tutors") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":8,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n            <select class=\"pull-left\" name=\"day\" placeholder=\"Select day\">\n                <option value=\"1\">Monday</option>\n                <option value=\"2\">Tuesday</option>\n                <option value=\"3\">Wednesday</option>\n                <option value=\"4\">Thursday</option>\n                <option value=\"5\">Friday</option>\n            </select>\n        </div>\n        <div class=\"col-sm-3 text-right\">\n            <button class=\"btn btn-default\" data-add-tutortime>Add new time</button>\n        </div>\n    </div>\n    <div class=\"row\" data-tutor-timerow>\n        <div class=\"col-sm-12\">\n            <table class=\"timetable-table table table-striped table-bordered table-responsive\">\n                <thead>\n                    <tr>\n                        <th></th>\n                        <th>Students</th>\n                        <th style=\"width:110px\">Start</th>\n                        <th style=\"width:110px\">End</th>\n                        <th>Room</th>\n                        <th>Instrument</th>\n                        <th></th>\n                    </tr>\n                </thead>\n                <tbody class=\"o-sortable\">\n\n                </tbody>\n            </table>\n        </div>\n    </div>\n</div>";
},"useData":true});
this["LE"]["templates"]["timetable_tutor_time"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":8,"column":27},"end":{"line":8,"column":33}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":35},"end":{"line":8,"column":43}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"year") || (depth0 != null ? lookupProperty(depth0,"year") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"year","hash":{},"data":data,"loc":{"start":{"line":8,"column":45},"end":{"line":8,"column":53}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":8,"column":54},"end":{"line":8,"column":63}}}) : helper)))
    + ")</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":24,"column":27},"end":{"line":24,"column":33}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":24,"column":35},"end":{"line":24,"column":43}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr data-timeentry data-entry-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"entryId") || (depth0 != null ? lookupProperty(depth0,"entryId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entryId","hash":{},"data":data,"loc":{"start":{"line":1,"column":34},"end":{"line":1,"column":47}}}) : helper)))
    + "\">\n    <td data-draggable class=\"vertical-middle\">\n        <i class=\"glyphicon glyphicon-resize-vertical\"></i>\n    </td>\n    <td data-students>\n        <select name=\"students[]\" class=\"form-control\" multiple>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"students") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":9,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n    </td>\n    <td data-start style=\"width:110px\">\n        <input type=\"text\" class=\"form-control\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"start") || (depth0 != null ? lookupProperty(depth0,"start") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start","hash":{},"data":data,"loc":{"start":{"line":13,"column":55},"end":{"line":13,"column":64}}}) : helper)))
    + "\">\n    </td>\n    <td data-end style=\"width:110px\">\n        <input type=\"text\" class=\"form-control\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"end") || (depth0 != null ? lookupProperty(depth0,"end") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"end","hash":{},"data":data,"loc":{"start":{"line":16,"column":55},"end":{"line":16,"column":62}}}) : helper)))
    + "\">\n    </td>\n    <td data-room>\n        <input type=\"text\" class=\"form-control\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"room") || (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"room","hash":{},"data":data,"loc":{"start":{"line":19,"column":55},"end":{"line":19,"column":63}}}) : helper)))
    + "\">\n    </td>\n    <td data-instrument>\n        <select name=\"instrument\" class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"instruments") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":25,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n    </td>\n    <td data-remove>\n        <button class=\"btn btn-default btn-icon\"><i class=\"glyphicon glyphicon-trash\"></i></button>\n    </td>\n</tr>";
},"useData":true});
this["LE"]["templates"]["tutortimetable_time"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":8,"column":27},"end":{"line":8,"column":33}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":35},"end":{"line":8,"column":43}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"year") || (depth0 != null ? lookupProperty(depth0,"year") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"year","hash":{},"data":data,"loc":{"start":{"line":8,"column":45},"end":{"line":8,"column":53}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":8,"column":54},"end":{"line":8,"column":63}}}) : helper)))
    + ")</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":24,"column":27},"end":{"line":24,"column":33}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":24,"column":35},"end":{"line":24,"column":43}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr data-timeentry data-entry-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"entryId") || (depth0 != null ? lookupProperty(depth0,"entryId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entryId","hash":{},"data":data,"loc":{"start":{"line":1,"column":34},"end":{"line":1,"column":47}}}) : helper)))
    + "\">\n    <td data-draggable class=\"vertical-middle\">\n        <i class=\"glyphicon glyphicon-resize-vertical\"></i>\n    </td>\n    <td data-students>\n        <select name=\"students[]\" class=\"form-control\" multiple>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"students") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":9,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n    </td>\n    <td data-start style=\"width:110px\">\n        <input type=\"text\" class=\"form-control\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"start") || (depth0 != null ? lookupProperty(depth0,"start") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start","hash":{},"data":data,"loc":{"start":{"line":13,"column":55},"end":{"line":13,"column":64}}}) : helper)))
    + "\">\n    </td>\n    <td data-end style=\"width:110px\">\n        <input type=\"text\" class=\"form-control\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"end") || (depth0 != null ? lookupProperty(depth0,"end") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"end","hash":{},"data":data,"loc":{"start":{"line":16,"column":55},"end":{"line":16,"column":62}}}) : helper)))
    + "\">\n    </td>\n    <td data-room>\n        <input type=\"text\" class=\"form-control\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"room") || (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"room","hash":{},"data":data,"loc":{"start":{"line":19,"column":55},"end":{"line":19,"column":63}}}) : helper)))
    + "\">\n    </td>\n    <td data-instrument>\n        <select name=\"instrument\" class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"instruments") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":25,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n    </td>\n</tr>";
},"useData":true});